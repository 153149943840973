import React, { useEffect, useState, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import ButtonNew from 'components/inputs/ButtonNew';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';
import QuickSettingsPopoverContent from './QuickSettingsPopoverContent';
import {
  fetchMatrix,
  addFavoriteMatrix,
  removeFavoriteMatrix,
  saveMatrix,
} from 'actions/matrices';
import { getMatrix, canDelete, shouldFetchMatrix } from 'constants/matrices';
import { useCanEdit } from 'hooks/matrices';
import './MatrixListItem.scss';
import Popover from '../Popover';
import MatrixDeletePopoverWrapper from './MatrixDeletePopoverWrapper';
import Icon from 'components/Icon';
import ViewOnlyBadge from './ViewOnlyBadge';
import * as urls from 'constants/urls';

const MatrixListItem = ({
  matrix,
  onClick,
  selected,
  showCreatedBy = false,
  showImage = true,
  showModifiedDate = false,
  draggable = false,
  folderId,
  setDeletePopoverOpen,
  deletePopoverOpen,
  setQuickSettingsPopoverOpen,
  quickSettingsPopoverOpen,
}) => {
  const dispatch = useDispatch();
  const canEdit = useCanEdit();

  const deleteRef = useRef(null);
  const quickSettingsRef = useRef(null);

  const { matrices } = useSelector(state => state.matrices);
  const { user, userData } = useSelector(state => state.auth);
  const deletable = canDelete(matrix, user, userData);
  const editable = canEdit(matrix);

  let { url } = useRouteMatch();

  const shareUrl = useMemo(() => {
    let path = matrix?.parentId ? urls.experiment(matrix) : urls.matrix(matrix);
    path = `${window.location.origin}/#${path}`;
    return path;
  }, [matrix]);

  const parentMatrix = matrix.parentId
    ? getMatrix(matrices, matrix.parentId)
    : null;

  useEffect(() => {
    if (matrix.parentId && shouldFetchMatrix(matrix.id, matrix)) {
      dispatch(fetchMatrix(matrix.id));
    }
  }, [dispatch, matrix]);

  const [isFavoriteHovered, setFavoriteHovered] = useState(false);

  const [isListItemHovered, setListItemHovered] = useState(false);

  const clickItem = e => {
    if (isFavoriteHovered) return;
    onClick(e);
  };

  const favoriteIsAllowed = useMemo(() => {
    const isPrivate = !matrix.isPublic;
    const userId = user?.claims?.user_id;
    const isOwner = matrix?.roles?.[userId] === 'owner';
    return isPrivate && isOwner;
  }, [matrix, user]);

  const favorited = useMemo(
    () =>
      matrix?.favorited
        ? matrix.favorited.includes(user?.claims?.user_id)
        : false,
    [matrix, user]
  );

  const onClickFavorite = () => {
    if (!favoriteIsAllowed) return;
    if (favorited) {
      dispatch(removeFavoriteMatrix(matrix.id, user.claims.user_id));
    } else {
      dispatch(addFavoriteMatrix(matrix.id, user.claims.user_id));
    }
    dispatch(saveMatrix(matrix.id));
  };

  const actualMatrixLocation = useMemo(() => {
    if (matrix?.parentId && !matrix?.folder) return true;
    return matrix?.folder === folderId || (!folderId && !matrix?.folder);
  }, [matrix, folderId]);

  return (
    <>
      <div
        className={classNames('MatrixListItem', {
          highlighted: selected,
        })}
        onMouseEnter={() => setListItemHovered(true)}
        onMouseLeave={() => setListItemHovered(false)}
      >
        <div
          className={classNames(
            'MatrixListItem-main',
            matrix.isPublic ? `id${matrix.id}` : null, //A bug in reactour requires not starting a class or ID with a number
            {
              isExperiment: matrix.parentId !== null,
              draggable,
              // Public matrices should never be low-vis
              'low-vis': !matrix.isPublic && !actualMatrixLocation,
            }
          )}
          onClick={clickItem}
        >
          {showImage ? (
            <div className="MatrixListItem-image">
              <img draggable="false" src={matrix.imageUrl} alt="" />
            </div>
          ) : null}
          <div className="MatrixListItem-background">
            {favoriteIsAllowed && (isListItemHovered || favorited) ? (
              <div
                className="MatrixListItem-favorite-icon"
                onClick={onClickFavorite}
                onMouseEnter={() => setFavoriteHovered(true)}
                onMouseLeave={() => setFavoriteHovered(false)}
              >
                <Icon
                  className="icon-green"
                  icon={favorited ? 'filledStar' : 'emptyStar'}
                  alt="favorite"
                />
              </div>
            ) : (
              <div className="MatrixListItem-favorite-icon hidden">
                <Icon
                  className="icon-green"
                  icon={'emptyStar'}
                  alt="favorite"
                />
              </div>
            )}

            <div className="MatrixListItem-matrixDetails">
              <div className="MatrixListItem-name-container">
                <div className="MatrixListItem-name">
                  <div className="MatrixListItem-matrixName">
                    {parentMatrix ? parentMatrix.name : matrix.name}
                  </div>
                  {parentMatrix ? (
                    <div className="MatrixListItem-experimentName">
                      {matrix.name}
                    </div>
                  ) : null}
                </div>
                {matrix?.isViewOnly ? <ViewOnlyBadge /> : null}
              </div>
              <div className="MatrixListItem-text">
                <div className="MatrixListItem-meta">
                  {showCreatedBy && matrix.createdBy ? (
                    <div className="MatrixListItem-createdBy text-interface-plain">
                      {matrix.createdBy}
                    </div>
                  ) : null}
                  {showModifiedDate ? (
                    <div className="MatrixListItem-modifiedDate text-interface-plain">
                      Updated {matrix.modified?.format('MM/DD/YY')}
                    </div>
                  ) : null}
                  <div className="icon-container">
                    {matrix.savedByUser && editable ? (
                      <div ref={quickSettingsRef}>
                        <ButtonNew
                          icon="gear"
                          onClick={() => setQuickSettingsPopoverOpen(matrix.id)}
                          buttonClasses={classNames('icon-button', {
                            selected: quickSettingsPopoverOpen,
                          })}
                        />
                      </div>
                    ) : null}

                    {deletable ? (
                      <div ref={deleteRef}>
                        <ButtonNew
                          icon="trash"
                          onClick={() => setDeletePopoverOpen(matrix.id)}
                          buttonClasses={classNames('icon-button', {
                            selected: deletePopoverOpen,
                          })}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>{' '}
        </div>
      </div>
      {/* Delete popover */}
      <Popover
        title={`Delete ${matrix.parentId ? 'experiment' : 'matrix'}`}
        content={
          <MatrixDeletePopoverWrapper
            matrixId={matrix.id}
            onDelete={() => setDeletePopoverOpen(matrix.id)}
            onCancel={() => setDeletePopoverOpen(matrix.id)}
          />
        }
        isOpen={deletePopoverOpen}
        parentRef={deleteRef.current}
      />
      {/* Quick settings popover */}
      <Popover
        title={`Quick settings`}
        content={
          <QuickSettingsPopoverContent
            matrix={matrix}
            url={shareUrl}
            onCancel={() => setQuickSettingsPopoverOpen(null)}
          />
        }
        isOpen={quickSettingsPopoverOpen}
        parentRef={quickSettingsRef.current}
      />
    </>
  );
};
export default MatrixListItem;
