import React from 'react';
import { Range, getTrackBackground } from 'react-range';
import classNames from 'classnames';
import InputField from 'components/inputs/InputField';
import './MultiRange.scss';

const MultiRange = ({
  disabled,
  label,
  onChange,
  min,
  max,
  step,
  value,
  dark,
}) => (
  <InputField label={label} dark={dark || false}>
    <div
      className={classNames('MultiRange', 'text-interface-small', {
        disabled,
      })}
    >
      <div className="MultiRange-range-number"> {value[0]}</div>
      <div className="MultiRange-range-selector">
        <Range
          disabled={disabled}
          min={min}
          max={max}
          values={value}
          step={step}
          onChange={onChange}
          renderTrack={({ props, children }) => (
            <div
              onMouseDown={props.onMouseDown}
              onTouchStart={props.onTouchStart}
              style={{
                ...props.style,
                height: '36px',
                display: 'flex',
                width: '100%',
              }}
            >
              <div
                ref={props.ref}
                style={{
                  height: '5px',
                  width: '100%',
                  borderRadius: '4px',
                  background: getTrackBackground({
                    colors: [
                      '#E9E9EA',
                      disabled ? '#E9E9EA' : '#303752',
                      '#E9E9EA',
                    ],
                    values: value,
                    min: min,
                    max: max,
                  }),
                  alignSelf: 'center',
                }}
              >
                {children}
              </div>
            </div>
          )}
          renderThumb={({ props, isDragged, index }) => (
            <div
              {...props}
              style={{
                ...props.style,
                height: '15px',
                width: '15px',
                borderRadius: '50%',
                backgroundColor: disabled ? '#E9E9EA' : '#303752',
              }}
            />
          )}
        />
      </div>
      <div className="MultiRange-range-number"> {value[1]}</div>
    </div>
  </InputField>
);

export default MultiRange;
