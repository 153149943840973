import React, { useState, useEffect, useMemo, useCallback } from 'react';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { setNodeHover } from 'actions/viewStates';
import { getValueForAbundance } from 'constants/fcm';
import { useEditableExperiment } from 'hooks/experiments';
import ButtonNew from './inputs/ButtonNew';
import './TrophicNode.scss';

const NodeAbundance = ({ node, abundance }) => {
  // Get the node abundance value
  const nodeAbundance = getValueForAbundance(node, abundance);

  return (
    <>
      <div className="NodeAbundance">
        <div id={`${node.id}-range`} className="NodeAbundance-range">
          {nodeAbundance}
        </div>
      </div>
    </>
  );
};

const TrophicNode = ({ node, matrix, isViewOnly, fixed, nodeScaling }) => {
  const { user } = useSelector(state => state.auth);

  const history = useHistory();
  const getEditableExperiment = useEditableExperiment();
  const dispatch = useDispatch();
  const hoverNode = useSelector(state => state.viewStates.hoverNode);
  const hoverNodeConnected =
    hoverNode &&
    [
      ...Object.keys(matrix.nodes[hoverNode]?.influences || {}),
      ...Object.values(matrix?.nodes || {}).reduce(
        (acc, n) =>
          Object.keys(n?.influences || {}).indexOf(hoverNode) >= 0
            ? [...acc, n.id]
            : acc,
        []
      ),
    ].indexOf(node.id) >= 0;

  // Uses a local state for the abundance value that shows when dragging
  const [abundance, setAbundance] = useState(
    node.fixed ? Number(node.abundance) : Number(node.abundanceResult)
  );
  // Make sure the abundances are changed when the node is updated from parent
  useEffect(
    () => setAbundance(node.fixed ? node.abundance : node.abundanceResult),
    [node]
  );

  const onNodeSettingsClick = useCallback(() => {
    // Navigate to overrides for this node
    // Only allow overrides in a matrix if the user is logged in
    if (!matrix?.parentId && user) {
      history.push(`/matrices/${matrix?.id}/overrides/${node.id}`);
    }
    // Otherwise get or make an experiment (anonymous users use this route)
    else {
      const editableExperiment = getEditableExperiment(matrix);
      const { parentId, id } = editableExperiment;

      history.push(
        `/matrices/${parentId}/experiments/${id}/overrides/${node.id}`
      );
    }
  }, [user, getEditableExperiment, history, node, matrix]);

  const onNodeHover = nodeId => e => {
    e.stopPropagation();
    if (hoverNode !== nodeId) dispatch(setNodeHover(nodeId));
  };

  return (
    <div
      className={classnames(
        'TrophicNode',
        `id${node.id}`, //id tag is for the tour
        hoverNode && (hoverNode === node.id ? 'hover' : 'fadedOut'),
        hoverNodeConnected && 'hoverConnected',
        {
          'TrophicNodePlay-fixed-border': fixed,
        }
      )}
      onMouseMove={onNodeHover(node.id)}
      style={{
        width: `${nodeScaling * 100}px`,
        height: `${nodeScaling * 100}px`,
      }}
    >
      <div className="TrophicNodeImageWrap">
        {node.imageUrl ? (
          <img
            className="TrophicNodeImage"
            src={node.imageUrl}
            alt={node.name}
          />
        ) : null}
      </div>

      <div className="TrophicNodePlayInner">
        <NodeAbundance node={node} abundance={abundance} />
        <div className="TrophicNodePlayInner-name-container">
          <div className="TrophicNodePlayInner-name">{node.name}</div>
        </div>
      </div>

      {!isViewOnly ? (
        <div className="TrophicNode-settings-button-container">
          <ButtonNew
            buttonClasses="TrophicNode-settings-button interactiveTourCreatingExperiment-4"
            onClick={onNodeSettingsClick}
            icon="pencilGray"
            iconClasses="icon-dark-blue"
          />
        </div>
      ) : null}
    </div>
  );
};

export default TrophicNode;
