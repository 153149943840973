import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { fetchMatrix } from 'actions/matrices';
import { getMatrix, shouldFetchMatrix } from 'constants/matrices';
import './MatrixListItemPublic.scss';

// TODO these should go in matrices, check with Dean?
const descriptions = {
  'Learning modules':
    'Expand your understanding of ecosystem and interspecies dynamics with our education modules',
  'MPG Grasslands':
    'Dig deeper into the matrix using this grassland ecosystem to understand the complexities of management actions between and within trophic levels',
  'Lake trout introduction to Yellowstone Lake':
    'See how illegally introduced lake trout impacts the ecosystem in Yellowstone Lake',
  'Red squirrel introduction to Newfoundland':
    'Understand how intentional red squirrel introduction affects other ecosystem players in Newfoundland',
  'Simple Ecosystem':
    'Explore the fundamentals behind the matrix with a simple ecosystem structure',
};

const MatrixListItemPublic = ({ matrix, onClick }) => {
  const dispatch = useDispatch();

  const [isListItemHovered, setListItemHovered] = useState(false);

  useEffect(() => {
    if (shouldFetchMatrix(matrix.id, matrix)) {
      dispatch(fetchMatrix(matrix.id));
    }
  }, [dispatch, matrix]);

  const clickItem = e => {
    onClick(e);
  };

  return (
    <>
      <div
        className={classNames('MatrixListItemPublic', `id${matrix.id}`, {
          highlighted: isListItemHovered,
        })}
        onClick={clickItem}
        onMouseEnter={() => setListItemHovered(true)}
        onMouseLeave={() => setListItemHovered(false)}
      >
        <div className="MatrixListItemPublic-image">
          <img draggable="false" src={matrix.imageUrl} alt="" />
        </div>
        <div className="MatrixListItemPublic-text-container">
          <div className="MatrixListItemPublic-text-title">{matrix.name}</div>
          <div
            className="MatrixListItemPublic-text-description"
            title={descriptions[matrix.name]}
          >
            {descriptions[matrix.name]}
          </div>
        </div>
      </div>
    </>
  );
};
export default MatrixListItemPublic;
