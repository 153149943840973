import React, { useRef, useState, useEffect } from 'react';
import classNames from 'classnames';
import * as d3 from 'd3';
import icons from 'img/icons';
import { trophicLevels } from 'constants/nodes';
import './NodePill.scss';

const MAX_WIDTH = 185;
const PADDING = 30;
const CONNECTIONS_PADDING = 10;

const NodePill = ({
  node,
  connectionsCount,
  opacity = 1,
  width,
  height = 24,
  hover,
  disabled = false,
  backgroundColor,
}) => {
  const ref = useRef(null);
  const [measuredWidth, setMeasuredWidth] = useState(0);
  const [name, setName] = useState(node.name);
  const displayConnections = typeof connectionsCount === 'number';

  useEffect(() => {
    // Measure the width of the text after render
    const textWidth = d3
      .select(ref.current)
      .node()
      .getBoundingClientRect().width;
    const totalPadding =
      PADDING + (displayConnections ? CONNECTIONS_PADDING : 0);
    setMeasuredWidth(textWidth + totalPadding);
    // If we need to truncate the text, take a proportional guess on how
    // many letters we need to truncate.
    if (width && width - totalPadding < textWidth) {
      // Calculate the percentage of available space to how long the text is currently
      const truncatePercent = (width - totalPadding) / textWidth;
      // Sets the name to a truncated version based on the percentage,
      // Not perfect, but better than looping until it fits.
      setName(
        node.name.substring(0, Math.floor(node.name.length * truncatePercent)) +
          '...'
      );
    }
  }, [displayConnections, width, node.name]);

  const trophicClassName = node.trophicLevel.toLowerCase().replaceAll(' ', '-');
  const icon = trophicLevels.filter(
    ({ name }) => name === node.trophicLevel
  )?.[0]?.icon;

  const totalWidth = width || measuredWidth || MAX_WIDTH;

  return (
    <svg
      className={classNames('NodePill')}
      style={{ opacity, width: totalWidth, height }}
    >
      <rect
        className={classNames('NodePill-background', {
          [trophicClassName]: !disabled,
          disabled: disabled,
          hover: displayConnections && hover,
        })}
        rx={4}
        ry={4}
        width={totalWidth}
        style={backgroundColor ? { fill: backgroundColor } : {}}
      />
      {icon ? (
        <image
          x={(height - 14) / 2}
          y={(height - 14) / 2}
          height={14}
          className={classNames('NodePill-icon', { disabled })}
          href={icon}
        />
      ) : null}
      <text
        ref={ref}
        className={classNames('NodePill-name', { disabled })}
        x={height}
        y={height / 2 + 1}
        dominantBaseline="middle"
      >
        {name}
      </text>
    </svg>
  );
};
export default NodePill;
