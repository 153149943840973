import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import MatrixListItemPublic from 'components/matrices/MatrixListItemPublic';
import MatrixListItemEducation from 'components/matrices/MatrixListItemEducation';
import { fetchMatrix, saveMatrix } from 'actions/matrices';
import { finishEdit } from 'actions/matrixSettings';
import { shouldFetchMatrix } from 'constants/matrices';
import * as urls from 'constants/urls';
import './MatrixListPublic.scss';
import { SettingsNumberField } from 'components/Settings';

const EducationModuleListItem = ({ setEducationTab }) => {
  const fakeMatrix = {
    imageUrl: 'images/education-modules.png',
    name: 'Learning modules',
  };
  return (
    <div className="interactiveTourGettingStarted-2">
      <MatrixListItemPublic matrix={fakeMatrix} onClick={setEducationTab} />
    </div>
  );
};

const MatrixListPublic = ({
  matrices,
  showEducation,
  setEducationTab,
  galleryTab,
}) => {
  const [matricesOrder, setMatrices] = useState();

  const { isAdmin } = useSelector(state => state.auth.userData);

  const dispatch = useDispatch();

  const matricesLoading = useSelector(state => state.matrices._loading);
  const matricesLoaded = useSelector(state => state.matrices._loaded);

  const history = useHistory();
  const goToMatrix = matrix => history.push(urls.matrix(matrix));

  const onClick = item => {
    goToMatrix(item);
  };

  // Sort matrices in order
  useEffect(() => {
    if (matrices) {
      matrices.forEach(matrix => {
        if (
          shouldFetchMatrix(matrix.id, matrix, matricesLoaded, matricesLoading)
        ) {
          dispatch(fetchMatrix(matrix.id));
        }
      });

      const sortedMatrices = matrices.sort(
        (a, b) => (a?.order ?? 0) - (b?.order ?? 0)
      );

      setMatrices(sortedMatrices);
    }
  }, [dispatch, matrices, matricesLoaded, matricesLoading]);

  const onUpdateOrder = useCallback(
    (matrixId, nextIndex) => {
      let updatedIndex = nextIndex - 1;
      let selectedMatrix = matricesOrder.find(m => m.id === matrixId);
      let nextOrder = matricesOrder.filter(m => m.id !== matrixId);
      nextOrder.splice(updatedIndex, 0, selectedMatrix);
      nextOrder = nextOrder.map((m, i) => ({ ...m, order: i }));

      setMatrices(nextOrder);

      nextOrder.forEach(matrix => {
        matrix.savedByUser = true;
        dispatch(finishEdit(matrix, false));
        dispatch(saveMatrix(matrix.id));
      });
    },
    [dispatch, matricesOrder]
  );

  return (
    <>
      <div
        className="MatrixListPublic interactiveTourGettingStarted-1"
        onDragOver={e => e.preventDefault()}
      >
        {(matricesOrder || matrices).map((matrix, i) => (
          <div
            key={matrix?.id ?? i}
            className="MatrixListPublic-item-container"
          >
            {galleryTab === 'education' ? (
              <MatrixListItemEducation
                key={matrix?.id}
                matrix={matrix}
                onClick={() => {
                  onClick(matrix);
                }}
                index={i}
              />
            ) : (
              <MatrixListItemPublic
                key={matrix?.id}
                matrix={matrix}
                onClick={() => {
                  onClick(matrix);
                }}
              />
            )}
            {isAdmin &&
            matricesOrder?.length &&
            matricesLoaded?.[matrix?.id] ? (
              <div className="MatrixListPublic-order-input">
                <SettingsNumberField
                  label="Order"
                  onChange={v => onUpdateOrder(matrix?.id, v)}
                  step={1}
                  placeholder={'...'}
                  value={(matrix?.order ?? i) + 1}
                  min={'1'}
                  max={`${matricesOrder.length}`}
                />
              </div>
            ) : null}
          </div>
        ))}

        {showEducation ? (
          <EducationModuleListItem setEducationTab={setEducationTab} />
        ) : null}
      </div>
    </>
  );
};

export default MatrixListPublic;
