import React, { useMemo, useState, useEffect } from 'react';
import classnames from 'classnames';
import ButtonNew from 'components/inputs/ButtonNew';
import {
  SettingsDropdown,
  SettingsMultiRange,
  SettingsControlLabel,
} from 'components/Settings';
import './ConnectionInput.scss';

const ConnectionInput = ({
  matrix,
  onAddConnections,
  connections,
  activeConnection,
  onUpdateConnection,
}) => {
  const [fromNode, setFromNode] = useState(null);
  const [toNode, setToNode] = useState(null);
  const [strengthRange, setStrengthRange] = useState([0, 1]);

  const nodes = useMemo(() => matrix.nodes, [matrix]);

  const fromNodeOptions = useMemo(() => {
    return Object.values(nodes)
      .filter(({ id }) => {
        let remainingInfluences = Object.keys(nodes[id]?.influences);
        const existingConnections = connections
          .filter(n => n.fromNode === id)
          .map(n => n.toNode);
        remainingInfluences = remainingInfluences.filter(
          n => !existingConnections.includes(n)
        );
        return !!remainingInfluences.length;
      })
      .sort((a, b) => a.name.localeCompare(b.name))
      .map(({ id, name }) => ({ label: name, value: id }));
  }, [nodes, connections]);

  const toNodeOptions = useMemo(() => {
    return Object.values(nodes)
      .filter(({ id }) => {
        const isInfluenced = nodes[fromNode]?.influences[id] !== undefined;
        const alreadyConnected = connections.find(
          node => node.fromNode === fromNode && node.toNode === id
        );
        return isInfluenced && !alreadyConnected;
      })
      .sort((a, b) => a.name.localeCompare(b.name))
      .map(({ id, name }) => ({ label: name, value: id }));
  }, [fromNode, nodes, connections]);

  const disableAddConnections = useMemo(() => {
    if (!fromNode || !toNode) return true;
    return connections.some(
      node => node.fromNode === fromNode && node.toNode === toNode
    );
  }, [connections, fromNode, toNode]);

  const getDefaultStrengthRange = (fromId, toId) => {
    const node = matrix?.nodes?.[fromId];
    const influence = Number(node?.influences?.[toId]);
    if (isNaN(influence)) return;
    // Have to do this because of how JS handles floats
    const decimalPlaces = `${influence}`.split('.')[1]?.length ?? 0;
    const variationAmount = Math.abs(influence) * 0.5;
    let min = Number(influence) - Number(variationAmount);
    if (decimalPlaces) {
      min = min.toFixed(decimalPlaces);
    }
    min = Math.min(Math.max(min, -1), 1);
    let max = influence + variationAmount;
    if (decimalPlaces) {
      max = max.toFixed(decimalPlaces);
    }
    max = Math.min(Math.max(max, -1), 1);

    return [min, max];
  };

  useEffect(() => {
    if (!fromNode || !toNode) return;
    const existingConnection = connections.find(
      c => c.fromNode === fromNode && c.toNode === toNode
    );
    const strengthRange = existingConnection
      ? existingConnection.strengthRange
      : getDefaultStrengthRange(fromNode, toNode);
    if (!strengthRange) return;
    setStrengthRange(strengthRange);
  }, [matrix, toNode, fromNode, connections]);

  const onImportAllConnections = () => {
    const centralNode = fromNode;
    const influencedNodes = Object.keys(
      matrix?.nodes?.[centralNode]?.influences ?? {}
    );
    const connectionsOut = influencedNodes
      .map(nodeId => {
        if (nodeId === centralNode) return false;
        const strengthRange = getDefaultStrengthRange(centralNode, nodeId);
        return { fromNode: centralNode, toNode: nodeId, strengthRange };
      })
      .filter(Boolean);

    const influencerNodes = Object.entries(matrix?.nodes).reduce(
      (acc, [k, v]) => {
        if (v?.influences?.[centralNode]) {
          acc.push(k);
        }
        return acc;
      },
      []
    );
    const connectionsIn = influencerNodes.map(nodeId => {
      const strengthRange = getDefaultStrengthRange(nodeId, centralNode);
      return { fromNode: nodeId, toNode: centralNode, strengthRange };
    });

    onAddConnections(connectionsOut.concat(connectionsIn));
  };

  useEffect(() => {
    if (activeConnection) {
      const {
        fromNode: from,
        toNode: to,
        strengthRange: strength,
      } = activeConnection;
      setFromNode(from);
      setToNode(to);
      setStrengthRange(strength);
    }
  }, [activeConnection]);

  useEffect(() => {
    if (
      !fromNodeOptions.map(o => o.value).includes(fromNode) &&
      activeConnection?.fromNode !== fromNode
    ) {
      setFromNode(null);
    }
    if (
      !toNodeOptions.map(o => o.value).includes(toNode) &&
      activeConnection?.toNode !== toNode
    ) {
      setToNode(null);
    }
  }, [fromNodeOptions, toNodeOptions, fromNode, toNode]);

  return (
    <div className="ConnectionInput">
      <SettingsDropdown
        label="Select first node"
        options={fromNodeOptions}
        value={fromNode ? nodes[fromNode].name : null}
        onChange={setFromNode}
      />
      <ButtonNew
        buttonClasses={classnames('secondary-button-small', {
          disabled:
            !fromNode || !fromNodeOptions.some(n => n.value === fromNode),
        })}
        iconLeft="plus"
        label="Import all connections"
        onClick={onImportAllConnections}
      />
      <div className="ConnectionInput-alternative-description-container">
        <div className="ConnectionInput-alternative-line" />
        <div className="ConnectionInput-alternative-description">
          or add connections manually
        </div>
        <div className="ConnectionInput-alternative-line" />
      </div>
      <SettingsDropdown
        label="Select connection"
        disabled={!fromNode}
        options={toNodeOptions}
        value={toNode ? nodes[toNode].name : null}
        onChange={setToNode}
      />
      <SettingsMultiRange
        disabled={!(fromNode && toNode)}
        label="Set interaction strength range"
        value={strengthRange}
        onChange={setStrengthRange}
        min={-1}
        max={1}
        step={0.01}
        showValues={true}
      />
      <ButtonNew
        buttonClasses={classnames('secondary-button-small', {
          disabled: !activeConnection && disableAddConnections,
        })}
        iconLeft="plus"
        label={`${activeConnection ? `Save` : `Add`} connection`}
        onClick={() => {
          activeConnection
            ? onUpdateConnection({ fromNode, toNode, strengthRange })
            : onAddConnections([{ fromNode, toNode, strengthRange }]);
        }}
      />
    </div>
  );
};

export default ConnectionInput;
