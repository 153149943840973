import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { fetchMatrix } from 'actions/matrices';
import { getMatrix, shouldFetchMatrix } from 'constants/matrices';
import './MatrixListItemEducation.scss';
import { trophicLevels } from 'constants/nodes';
import { ReactComponent as TrophicAnthropogenic } from 'img/icons/trophic-anthropogenic.svg';
import { ReactComponent as TrophicPrimaryConsumers } from 'img/icons/trophic-primary-consumers.svg';
import { ReactComponent as TrophicSecondaryConsumers } from 'img/icons/trophic-secondary-consumers.svg';
import { ReactComponent as TrophicPrimaryProducers } from 'img/icons/trophic-primary-producers.svg';
import { ReactComponent as TrophicDecomposers } from 'img/icons/trophic-decomposers.svg';
import { ReactComponent as TrophicAbiotic } from 'img/icons/trophic-abiotic.svg';

const colors = [
  { light: '#F8B799', dark: '#DC7F85', svgClass: 'svg-fill-0' },
  { light: '#BCBEA4', dark: '#648D8D', svgClass: 'svg-fill-1' },
  { light: '#91AEAE', dark: '#7B7D67', svgClass: 'svg-fill-2' },
  { light: '#E08087', dark: '#F8B799', svgClass: 'svg-fill-3' },
];

const arrayGrouper = (arr, size) => {
  const reversedArr = [...arr].reverse();
  let result = [];
  for (let i = 0; i < reversedArr.length; i += size) {
    result.push(reversedArr.slice(i, i + size).reverse());
  }
  return result;
};

export { arrayGrouper };

const MatrixListItemEducation = ({ matrix, onClick, index }) => {
  const dispatch = useDispatch();

  const [isListItemHovered, setListItemHovered] = useState(false);

  useEffect(() => {
    if (shouldFetchMatrix(matrix.id, matrix)) {
      dispatch(fetchMatrix(matrix.id));
    }
  }, [dispatch, matrix]);

  const clickItem = e => {
    onClick(e);
  };

  const icons = [
    TrophicAnthropogenic,
    TrophicPrimaryConsumers,
    TrophicSecondaryConsumers,
    TrophicPrimaryProducers,
    TrophicDecomposers,
    TrophicAbiotic,
  ];

  const moduloIndex = useMemo(() => {
    // Shifts color such that if there's 4 colors and rows of 4, it doesn't repeat exactly
    let updatedIndexes = Array.apply(null, Array(index + 1)).map((_, i) => i);
    updatedIndexes = arrayGrouper(updatedIndexes, colors.length);
    updatedIndexes = updatedIndexes.map((arr, i) => arr.map(v => v + i));
    updatedIndexes = updatedIndexes.flat();
    const updatedIndex = updatedIndexes.pop();
    const moduloIndex = updatedIndex % colors.length;
    return moduloIndex;
  }, [index]);

  const color = useMemo(() => {
    return colors[moduloIndex];
  }, [moduloIndex]);

  return (
    <>
      <div
        className={classNames('MatrixListItemEducation', `id${matrix.id}`, {
          highlighted: isListItemHovered,
        })}
        onClick={clickItem}
        onMouseEnter={() => setListItemHovered(true)}
        onMouseLeave={() => setListItemHovered(false)}
      >
        <div
          className="MatrixListItemEducation-background"
          style={{ backgroundColor: color?.light }}
        >
          <div
            className={`MatrixListItemEducation-icon-container svg-fill-${moduloIndex}`}
          >
            {icons.map((Icon, i) => (
              <Icon key={i} />
            ))}
          </div>
          <div className="MatrixListItemEducation-text-container">
            {matrix?.name}
          </div>
        </div>
      </div>
    </>
  );
};
export default MatrixListItemEducation;
