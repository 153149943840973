import React, { useState, useEffect, useCallback, useMemo } from 'react';
import useResizeObserver from '@react-hook/resize-observer';
import { useDispatch } from 'react-redux';
import { setField } from 'actions/nodeSettings';
import Dropdown from 'components/inputs/Dropdown';
import InputRow from 'components/inputs/InputRow';
import InputField from 'components/inputs/InputField';
import NumberField from 'components/inputs/NumberField';
import NodeSettingsMembershipFunctionShapeGraph from 'components/nodes/NodeSettingsMembershipFunctionShapeGraph';
import {
  activationFunctions,
  populationUnits,
  getDefaultMembershipFunctions,
} from 'constants/nodes';
import {
  augmentedMembership,
  membershipCategoryFilter,
  rescale,
  descale,
} from 'fcm/fcm';
import text from 'constants/text';
import './DensitiesContent.scss';
import InputColumn from 'components/inputs/InputColumn';
import ButtonSet from 'components/inputs/ButtonSet';

const makeOption = value => ({ label: value, value });

const DensitiesInput = ({ membershipFunctions, mean, readOnly }) => {
  const toggleOptions = [
    { label: '3 categories', value: 3 },
    { label: '5 categories', value: 5 },
  ];

  const [toggleState, setToggleState] = useState(
    Object.keys(membershipFunctions).length
  );

  const [activeMembershipFunctions, setActiveMembershipFunctions] = useState(
    membershipFunctions
  );

  const membershipCategories = useMemo(() => {
    return membershipCategoryFilter(
      augmentedMembership,
      activeMembershipFunctions
    );
  }, [activeMembershipFunctions]);

  const [minValue, setMin] = useState(
    activeMembershipFunctions[membershipCategories[0]][0][0]
  );
  const [meanValue, setMean] = useState(mean);
  const [maxValue, setMax] = useState(
    activeMembershipFunctions[
      membershipCategories[membershipCategories.length - 1]
    ][2][0]
  );

  const dispatch = useDispatch();

  const conditionalUpdate = () => {
    if (isNaN(minValue) || isNaN(meanValue) || isNaN(maxValue)) return;
    if (minValue >= meanValue || minValue >= maxValue) return;
    if (maxValue <= meanValue || maxValue <= minValue) return;

    let scaledFunctions = rescale(
      JSON.parse(JSON.stringify(activeMembershipFunctions)),
      mean
    );
    let newFunctions = descale(scaledFunctions, meanValue, minValue, maxValue);

    dispatch(setField('membershipFunctions', newFunctions));
    dispatch(setField('mean', meanValue));
  };

  useEffect(() => {
    setActiveMembershipFunctions(getDefaultMembershipFunctions(toggleState));
  }, [toggleState]);

  useEffect(conditionalUpdate, [
    activeMembershipFunctions,
    minValue,
    meanValue,
    maxValue,
    toggleState,
  ]);

  return (
    <div className="DensitiesInput">
      <InputRow>
        <InputField label={'Fuzzy categories'}>
          <ButtonSet
            onChange={setToggleState}
            options={toggleOptions}
            value={toggleState}
            label={'Fuzzy categories'}
            orientation={'horizontal'}
          />
        </InputField>
      </InputRow>
      <InputRow>
        <NumberField
          disabled={readOnly}
          key={0}
          label="Min"
          onBlur={v => setMin(+v)}
          onChange={v => setMin(+v)}
          value={minValue}
          validationMessage={
            +minValue >= +meanValue || +minValue >= +maxValue || isNaN(minValue)
              ? 'Invalid min'
              : ''
          }
        />
        <NumberField
          disabled={readOnly}
          key={1}
          label="Mode"
          onBlur={v => setMean(+v)}
          onChange={v => setMean(+v)}
          validationMessage={
            +minValue >= +meanValue ||
            +meanValue >= +maxValue ||
            isNaN(meanValue)
              ? 'Invalid mode'
              : ''
          }
          value={meanValue}
        />
        <NumberField
          disabled={readOnly}
          key={2}
          label="Max"
          onBlur={v => setMax(+v)}
          onChange={v => setMax(+v)}
          validationMessage={
            +minValue >= +maxValue ||
            +meanValue >= +maxValue ||
            isNaN(meanValue)
              ? 'Invalid max'
              : ''
          }
          value={maxValue}
        />
      </InputRow>
    </div>
  );
};

const PopulationUnitInput = ({ onChange, readOnly, value }) => {
  const modalRef = document.getElementById('matrix-modal');

  return (
    <Dropdown
      disabled={readOnly}
      label="Units"
      onChange={onChange}
      options={populationUnits
        .map(({ displayName }) => displayName)
        .map(makeOption)}
      value={
        populationUnits.find(obj => {
          return obj.name === value;
        })?.displayName || value
      }
      menuPortalTarget={document.body}
      closeMenuOnScroll={event =>
        modalRef && modalRef.firstChild.contains(event.target)
      }
    />
  );
};

const PopulationInput = ({ node, readOnly }) => {
  const dispatch = useDispatch();

  return (
    <div className="DensitiesContent-content">
      <DensitiesInput
        membershipFunctions={node.membershipFunctions}
        mean={node.mean}
        readOnly={readOnly}
      />
      <PopulationUnitInput
        readOnly={readOnly}
        onChange={inputValue => {
          dispatch(
            setField(
              'populationUnit',
              populationUnits.find(obj => {
                return obj.displayName === inputValue;
              }).name
            )
          );
        }}
        value={node.populationUnit}
      />
    </div>
  );
};

const ActivationFunction = ({ node, readOnly }) => {
  const dispatch = useDispatch();
  const modalRef = document.getElementById('matrix-modal');

  return (
    <div className="activation-function">
      <div className="NodeSettingsSectionBasic-column">
        <Dropdown
          disabled={readOnly}
          options={activationFunctions
            .map(af => af.displayName)
            .map(makeOption)}
          onChange={value =>
            dispatch(
              setField(
                'activationFunction',
                activationFunctions.find(obj => {
                  return obj.displayName === value;
                }).name
              )
            )
          }
          placeholder="Select Activation Function"
          value={
            activationFunctions.find(obj => {
              return obj.name === node.activationFunction;
            }).displayName
          }
          menuPortalTarget={document.body}
          closeMenuOnScroll={event =>
            modalRef && modalRef.firstChild.contains(event.target)
          }
        />
      </div>
      <div className="NodeSettingsSectionBasic-column">
        <div className="text-interface-description">
          {text.nodeSettings.activationFunction}
        </div>
      </div>
    </div>
  );
};

const DensitiesContent = ({ node, readOnly }) => {
  const [graphWidth, setGraphWidth] = useState(400);

  const getGraphWidth = useCallback(e => {
    const { contentRect } = e;
    const { width } = contentRect;
    setGraphWidth(width / 2);
  }, []);

  useResizeObserver(
    document?.getElementsByClassName('DensitiesContent')?.[0],
    getGraphWidth
  );

  return (
    <div className="DensitiesContent">
      <div className="DensitiesContent-row">
        <div className="NodeSettingsSectionBasic-column">
          <PopulationInput node={node} readOnly={readOnly} />
        </div>
        <div className="NodeSettingsSectionBasic-column">
          <NodeSettingsMembershipFunctionShapeGraph
            membershipFunctions={node.membershipFunctions}
            mean={node.mean}
            readOnly={readOnly}
            width={graphWidth}
            height={150}
          />
        </div>
      </div>
      <InputColumn>
        <div className="NodeSettingsSectionBasic-settings-title">
          ADVANCED (ACTIVATION FUNCTION)
        </div>
        <ActivationFunction node={node} readOnly={readOnly} />
      </InputColumn>
    </div>
  );
};

export {
  DensitiesContent,
  ActivationFunction,
  PopulationInput,
  DensitiesInput,
  PopulationUnitInput,
};
